import React from 'react';
import { Col, Divider, Grid, Row } from '@components/Grid';
import Layout from '@components/Layout';
import Portfolio from '@images/svgs/portfolio.svg';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PortfolioCard from '@components/PortfolioCard';
import Hero from '@components/Hero';
import SEO from '@components/Seo';
import Button from '@components/Button';
import styles from './styles.module.scss';

const Showcase = ({
    data: {
        page: {
            frontmatter: {
                title,
                description,
                graphic: { publicURL: graphic },
            },
        },
        portfolio: { edges: portfolio },
    },
}) => {
    return (
        <Layout>
            <SEO title="Portfolio" />
            <div>
                <Hero
                    title={title}
                    description={description}
                    image={graphic}
                    imageWrapperClassName="-mt-2"
                />
            </div>

            <section className={styles.portfolioWrapper}>
                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <Grid>
                    <div className={styles.portfolioCardContainer}>
                        <Row>
                            {portfolio?.map(data => (
                                <Col xs={12} key={data?.node?.frontmatter?.heading}>
                                    <div className={styles.portfolioCardWrapper}>
                                        <PortfolioCard data={data} />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Grid>

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />
            </section>

            <hr />
            <div className="bg-white">
                <div className="max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center">
                    <h2 className="my-2">Like what we do?</h2>
                    <p className="text-xl mt-2 font-medium  mx-auto max-w-4xl">
                        Let's make you a beautiful website, chat with us now.
                    </p>
                    <div className="mt-8 flex flex-col lg:flex-row justify-center items-center ">
                        <Link to="tel:01206259355">
                            <Button text="Phone us" />
                        </Link>
                        <Button
                            secondary
                            onClick={() => window?.FB?.CustomerChat?.show(true)}
                            text="Open chat"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query PortfolioQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                title: portfolio_title
                description: portfolio_description
                graphic: portfolio_graphic {
                    publicURL
                }
            }
        }
        portfolio: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/case-studies/(?!.md$)/" } }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        heading: portfolio_item_title
                        subHeading: portfolio_item_sub_heading
                        desktopImage: portfolio_item_desktop_image {
                            childImageSharp {
                                fluid(maxWidth: 550, maxHeight: 300) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        bgImage: portfolio_item_bg {
                            childImageSharp {
                                fluid(maxWidth: 650, maxHeight: 350) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        description: portfolio_item_description
                        date: portfolio_item_date
                        read_time: portfolio_item_readtime
                        services: portfolio_item_services
                        servicesCategories: portfolio_item_services_categories
                        favourite: portfolio_item_favourite
                        displayedServices: portfolio_item_displayed_services {
                            path: portfolio_item_displayed_services_link
                            name: portfolio_item_displayed_services_name
                        }
                    }
                }
            }
        }
    }
`;

export default Showcase;
