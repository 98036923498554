import React, { Fragment } from 'react';
import Img from 'gatsby-image';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Col, Divider, Grid, Row } from '@components/Grid';

import styles from './styles.module.scss';
import { filteredServices } from '@helpers/index';
const PortfolioCard = ({
    data: {
        node: {
            frontmatter: {
                heading,
                desktopImage,
                description,
                date,
                read_time,
                favourite,
                servicesCategories: portfolioServicesCategories,
                displayedServices,
                path,
            },
        },
    },
}) => {
    const {
        servicesCategories: { edges: allServicesCategories },
    } = useStaticQuery(graphql`
        query {
            servicesCategories: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/servicesCategories/(?!.md$)/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            groupCode: services_service_code
                            code: services_service_category_code
                            name: services_service_heading
                            path
                        }
                    }
                }
            }
        }
    `);
    const myFilteredServices = filteredServices(allServicesCategories, portfolioServicesCategories)
    return (
        <div className={styles.portfolioCard}>
            <Row>
                <Col xs={12} md={6}>
                    <Link to={path}>
                        <div className={styles.imageWrapper}>
                            <div className={styles.image}>
                                {!!desktopImage && (
                                    <Img
                                        style={{ height: '100%' }}
                                        fluid={desktopImage?.childImageSharp.fluid}
                                        imgStyle={{ objectFit: 'cover' }}
                                    />
                                )}
                            </div>
                        </div>
                    </Link>
                </Col>

                <Col xs={12} md={6}>
                    <div className={styles.cardContent}>
                        <Link to={path}>
                            <h4>{heading}</h4>
                            <p>{description}</p>
                            <p>
                                Last updated {date}. <span>{read_time} min read.</span>
                            </p>
                        </Link>
                        <div className={styles.tagsContainer}>
                            {!!favourite && (
                                <span className={`${styles.tag} ${styles.fav}`}>Favourite</span>
                            )}

                            {displayedServices?.map(({ name, path }) => {
                                return (
                                    <Link className={styles.tag} to={path} key={path}>
                                        <span>{name}</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PortfolioCard;
